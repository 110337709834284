import * as React from "react"
import PropTypes from "prop-types"
import Header from "./header"

const Layout = ({ children }) => {

  return (
    <>
      <div className="dark:bg-gray-800"
        style={{
          margin: `0 auto`,
          maxWidth: `90vw`,
        }}
      >
        <Header></Header>
        <main className="">{children}</main>
      </div>
      <footer className="bg-gray-100" style={{
          padding: `1.5rem 5vw`,
          maxWidth: `100vw`,
        }}>
          © {new Date().getFullYear()} Ethan Hancock
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout