import * as React from "react"
import { Link } from "gatsby"
import { Widget } from "@typeform/embed-react"
import Layout from "../components/layout"

const SecondPage = () => (
  <Layout>
    <div className="h-screen mt-10">
      <Widget id="Z06Q0cGU" style={{ height: "70%" }} className="my-form" />
    </div>
  </Layout>
)

export default SecondPage
